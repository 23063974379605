import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import VisibilitySensor from 'react-visibility-sensor';

function CircularStatistic({ value, text }) {
  return (
    <VisibilitySensor partialVisibility offset={{ bottom: 100 }}>
      {({ isVisible }) => (
        <div >
          <CircularProgressbar
            value={isVisible ? value : 0}
            // We use a custom function for the text to control its rendering.
            text={value + '%'}
            styles={buildStyles({
              pathTransitionDuration: 1.5,
              textColor: '#623a94',
              pathColor: '#e74b76',
              trailColor: '#ddd',
              textSize: '26px', // This should match the tspan fontSize
            })}
          />
          <p className="text-center font-semibold mt-2 break-words">{text}</p>
        </div>
      )}
    </VisibilitySensor>
  );
}
export default CircularStatistic;