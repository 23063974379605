import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const location = useLocation();
    const headerHeight = 150;
    const delay = 100; // Delay in milliseconds
  
    useEffect(() => {
      setTimeout(() => { // Add a delay before scrolling
        if (location.hash) {
          const sectionId = location.hash.replace('#', '');
          const section = document.getElementById(sectionId);
          if (section) {
            const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
              top: sectionTop - headerHeight,
              behavior: 'smooth'
            });
          }
        } else {
          window.scrollTo(0, 0);
        }
      }, delay);
    }, [location, delay]);
  
    return null;
}

export default ScrollToTop;