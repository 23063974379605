import React from 'react';
import servicesData from '../data/servicesData';
// import ContactForm from '../components/ContactForm';
// import warehousesData from '../data/warehousesData';
const ServicesPage = () => (
    <div className="flex flex-col container mx-auto mt-10">
        {servicesData.map(service => (
            <div className="flex md:flex-row flex-col items-center md:gap-20 mb-10 justify-center">
                <div className='flex md:w-1/3 md:mb-0 mb-4'>
                    <img className="w-full" src={service.imageUrl} alt={service.title} />
                </div>

                <div className="flex flex-col px-6 md:w-1/2">
                    <h2 className="text-3xl md:text-4xl font-bold mb-6">{service.title}</h2>
                    <ul className="square-bullets pl-5 space-y-2">
                        {service.description.map((item, index) => (
                            <li key={index} className=" text-base">{item}</li>
                        ))}
                    </ul>
                </div>

                {/* <div className="px-6 pt-4 pb-2">
                    <Link to={service.link} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Learn More
          </Link>
                </div> */}
            </div>
        ))}

        <section className="mb-20 px-4">
                <div className="flex flex-col md:flex-row justify-center items-center gap-6">
                    <h1 className="text-3xl md:text-4xl font-bold text-left w-full md:w-1/2">
                        Услуга ответственного хранения
                    </h1>
                    {/* Service 1 */}
                    <div className="gap-4 flex flex-col w-full md:w-1/2">
                        <h2 className="text-2xl font-semibold">Склады ответственного хранения компании «Артлок» позволят Вам сэкономить время и силы на самостоятельную организацию перевозок и хранения товаров:</h2>
                        <p>Склады ответственного хранения компании «Артлок» позволят Вам сэкономить время и силы на самостоятельную организацию перевозок и хранения товаров:</p>
                        <ul className="square-bullets pl-5 space-y-2">

                            <li className="text-base">Вы экономите на покупке сложного специализированного оборудования и его обслуживании.</li>
                            <li className=" text-base">Вы доверяете все операции команде профессионалов, вместо того, чтобы лично искать персонал.</li>

                        </ul>
                    </div>


                </div>
                {/* <div className="w-full md:w-1/2">
                    <ContactForm warehouses={warehousesData}></ContactForm>
                </div> */}
           
        </section>

    </div>
);

export default ServicesPage;