import React from 'react';
import CompanyHistorySection from '../sections/CompanyHistorySection.js';
import OurValuesSection from '../sections/OurValuesSection.js';
import LeaderMessageSection from '../sections/LeaderMessageSection.js';
// import OurTeamSection from '../sections/OurTeamSection.js';
import PartnerBenefitsSection from '../sections/PartnerBenefitsSection.js';
import AnticorruptionPolicy from '../sections/AnticorruptionPolicySection.js';
import GrowthPolicySection from '../sections/GrowthPolicySection.js';
// import QualityControlSection from '../sections/QualityControlSection.js';
function AboutUsPage() {

    return (
        <div className="container mx-auto mt-10">
            <CompanyHistorySection></CompanyHistorySection>
            <OurValuesSection></OurValuesSection>
            <LeaderMessageSection></LeaderMessageSection>
            {/* <OurTeamSection></OurTeamSection> */}
            <PartnerBenefitsSection></PartnerBenefitsSection>
            <AnticorruptionPolicy></AnticorruptionPolicy>
            <GrowthPolicySection></GrowthPolicySection>
            {/* <QualityControlSection></QualityControlSection> */}
            

        </div>
    );
}

export default AboutUsPage;
