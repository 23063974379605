import React from 'react';
import { Link } from 'react-router-dom';


function Navigation({ onLinkClick }) {

  return (

    < nav className='w-full'>
      <ul className="flex flex-col md:flex-row justify-center md:justify-end md:space-x-6 space-y-2 md:space-y-0 px-4 md:px-auto py-2 md:py-auto">
        <li className="md:hidden">
          <Link to="/about-us" className="text-lg font-semibold hover:text-accent1 inline-block" onClick={onLinkClick}>О Нас</Link>
        </li>
        
        <li className="hidden md:block group relative">
          <Link to="/about-us" onClick={onLinkClick} className="text-lg font-semibold hover:text-accent1 inline-block">О Нас</Link>
          <div className="absolute hidden group-hover:block bg-white shadow-md mt-0 rounded-lg">
            <Link to="/about-us#companyHistory" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100 rounded-t-lg">История компании</Link>
            <Link to="/about-us#ourValues" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100">Наши ценности</Link>
            <Link to="/about-us#leaderMessage" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100">Обращение руководителя</Link>
            {/* <Link to="/about-us#ourTeam" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100">Наша команда</Link> */}
            <Link to="/about-us#partnerBenefits" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100">Преимущества сотрудничества</Link>
            <Link to="/about-us#anticorruptionPolicy" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100">Антикоррупционная политика</Link>
            <Link to="/about-us#growthPolicy" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100">Политика устойчивого развития</Link>
            {/* <Link to="/about-us#qualityControl" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100">Система управления качеством</Link> */}
            {/* <Link to="/about-us#documents" onClick={onLinkClick} className="block px-4 py-2 text-sm text-gray-700 hover:bg-pink-100 rounded-b-lg">Документы</Link> */}
          </div>
        </li>

        <li className="text-lg font-semibold hover:text-accent1"><Link to="/warehouses" onClick={onLinkClick}>Склады</Link></li>
        <li className="text-lg font-semibold hover:text-accent1"><Link to="/services" onClick={onLinkClick}>Услуги</Link></li>
        <li className="text-lg font-semibold hover:text-accent1"><Link to="/contact" onClick={onLinkClick}>Контакты</Link></li>
        <li className="text-lg font-semibold hover:text-accent1"><Link to="/presentation" onClick={onLinkClick}>Презентация</Link></li>
      </ul>
    </nav>
  );

}

export default Navigation;