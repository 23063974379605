import{ useState, useEffect } from 'react';
import HexTile from '../components/HexTile'; // Component for hexagonal tiles
import Counter from '../components/Counter';

function LandingSection(){
    const [showFirstText, setShowFirstText] = useState(false);
    const [startFirstCount, setStartFirstCount] = useState(false);
    const [showSecondText, setShowSecondText] = useState(false);
    const [startSecondCount, setStartSecondCount] = useState(false);
    const [showThirdText, setShowThirdText] = useState(false);
    const [startThirdCount, setStartThirdCount] = useState(false);
    useEffect(() => {
      if (!startFirstCount) {
        const timer = setTimeout(() => setStartFirstCount(true), 0);
        return () => clearTimeout(timer);
      }
    }, [startFirstCount]);
  
    useEffect(() => {
      if (showFirstText && !startSecondCount) {
        const timer = setTimeout(() => setStartSecondCount(true), 1000);
        return () => clearTimeout(timer);
      }
    }, [showFirstText, startSecondCount]);
  
    useEffect(() => {
      if (showSecondText && !startThirdCount) {
        const timer = setTimeout(() => setStartThirdCount(true), 1000);
        return () => clearTimeout(timer);
      }
    }, [showSecondText, startThirdCount]);
    console.log('Rendering LandingPage');
 
    return(
        <section id='landingSection' className='flex flex-col md:flex-row justify-between items-center bg-map-pattern bg-auto bg-top bg-no-repeat p-6 md:pt-0 min-h-[750px]'>

        {/* First text and counter */}
        <div className='flex flex-col gap-10 pb-10 md:pb-0'>
          <div className="flex font-bold md:text-4xl text-3xl text-center">Лидер 3PL оператор в России</div>
          {/* Center Text at the Top */}
          <div className='flex items-center gap-4'>
            {startFirstCount && (
              <Counter end="15" duration={1500} onCountEnd={() => setShowFirstText(true)} startCounting={startFirstCount} />
            )}
            {showFirstText && <div className="animate-slideIn text-3xl">лет на рынке логистики</div>}
          </div>

          {/* Second text and counter */}
          <div className='flex items-center gap-4'>
            {startSecondCount && (
              <Counter end="68" duration={1500} onCountEnd={() => setShowSecondText(true)} startCounting={startSecondCount} />
            )}

            {showSecondText && <div className="animate-slideIn text-3xl"> тыс кв. м складских площадей</div>}
          </div>



          {/* Third text and counter */}

          <div className='flex items-center gap-4'>
            {startThirdCount && (
              <Counter end="12" duration={1500} onCountEnd={() => setShowThirdText(true)} startCounting={startThirdCount} />
            )}

            {showThirdText && <div className="animate-slideIn text-3xl">городов Российской Федерации</div>}
          </div>
        </div>



        {/* Right Side Hex Tiles */}
        <div className="hex-tiles-container text-center font-bold">
          {/* Example hex tiles */}
          <HexTile text="Сырьевые Товары" />
          <HexTile text="Товары С/Х Назначения" />
          <HexTile text="Товары Машиностроения" />
          <HexTile text="Строительные Товары" />
          <HexTile text="Пищевые Товары" />
          <HexTile text="Товары Народного Потребления" />
          <HexTile text="Целлюлозо Бумажные Товары" />
          {/* Add more HexTile components as needed */}
        </div>
         
      </section>
    )
  
}

export default LandingSection;