import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import warehousesData from '../data/warehousesData';
import WarehouseDetails from '../components/WarehouseDetails';

const WarehousePage = () => {
  const [mapState, setMapState] = useState({
    center: [51.023973, 45.695178],
    zoom: 1,
  });
  const [isPresentationShown, setIsPresentationShown] = useState(false)
  // Adjust zoom based on screen size
  useEffect(() => {
    const handleResize = () => {
      const zoomLevel = window.innerWidth < 768 ? 3 : 5; // Increase zoom for smaller screens
      setMapState((prevState) => ({
        ...prevState,
        zoom: zoomLevel,
      }));
    };

    // Set initial zoom based on current screen size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    const [selectedWarehouse, setSelectedWarehouse] = useState(warehousesData[0]);
  
    const onWarehouseSelect = (warehouse) => {
      setSelectedWarehouse(warehouse);
    };

    const togglePresentation = () => {
      setIsPresentationShown((prev) => !prev);
    };
  
    return (
      <div className="md:relative flex md:flex-row flex-col">
        {/* <div className="w-1/2">
          
          {warehousesData.map((warehouse, index) => (
            <button key={index} onClick={() => onWarehouseSelect(warehouse)}>
              {warehouse.title}
            </button>
          ))}
        </div> */}
        
        <div className="overflow-hidden md:h-screen w-screen h-[350px] md:relative">
          <YMaps>
            <Map defaultState={mapState} className="w-full h-full" >
              {warehousesData.map((warehouse, index) => (
                <Placemark
                  key={index}
                  geometry={[warehouse.lat, warehouse.lon]}
                  properties={{iconContent: warehouse.name}} options={{preset: 'islands#violetStretchyIcon'}}
                  onClick={() => onWarehouseSelect(warehouse)}
                />
              ))}
            </Map>
          </YMaps>
        </div>
        <div className={`flex flex-col  w-screen h-[600px] md:absolute md:mx-10 md:my-20 ${isPresentationShown ? 'md:w-auto md:h-auto': 'md:w-2/6'}`}>
          <WarehouseDetails warehouse={selectedWarehouse} togglePresentation = {togglePresentation} isPresentationShown ={isPresentationShown}/>
        </div>
      </div>
    );
  };
  
  export default WarehousePage;