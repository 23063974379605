import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";

// The workerSrc property must be specified.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Presentation() {
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    useEffect(() => {
      // Update the scale based on the window width or height
      const updateScale = () => {
          const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
          const pageWidth = 1707; // Adjusted for landscape A4 page at 72 dpi (A4 landscape width)
          const pageHeight = 960; // Adjusted for landscape A4 page at 72 dpi (A4 landscape height)
          const scaleByWidth = screenWidth / pageWidth;
          const scaleByHeight = screenHeight / pageHeight;
          // Choose the smaller scale to ensure the PDF fits within the screen both width and height wise
          const newScale = Math.min(scaleByWidth, scaleByHeight) * 1.6;
          setScale(newScale);
      };

      // Set scale on initial load
      updateScale();

      // Add event listener to resize the scale when window resizes
      window.addEventListener('resize', updateScale);

      // Cleanup listener when component unmounts
      return () => window.removeEventListener('resize', updateScale);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
  }
  return (
    <div className="max-w-screen justify-center flex  min-h-screen">
      <Document
        file="/presentation/Artlok.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
        
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} scale={scale}/>
          ),
        )}
      </Document>
    </div>
  );
}

export default Presentation;