import React from 'react';
import Partner from '../components/Partner'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function OurPartnersSection() {
  const partners = [
    { logo: '/client/client1.jpg', title: 'Кнауф Петроборд' },
    { logo: '/client/client2.png', title: 'ММ Картон' },
    { logo: '/client/client3.png', title: 'Европапир' },
    { logo: '/client/client4.jpg', title: 'Петробумага' },
    { logo: '/client/client5.png', title: 'ИД Проф-Пресс' },
    { logo: '/client/client6.jpg', title: 'Невская Бумага' },
    { logo: '/client/client7.jpg', title: 'ДИО-Пласт' },
    { logo: '/client/client8.png', title: 'УралКупер' },
    { logo: '/client/client9.png', title: 'Балтийская Целлюлоза' },
  ];

  const settings = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 800,
    adaptiveHeight: true,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
      // You can add more breakpoints here
    ]
  };
  return (
    <section id="ourPartners" className='my-8 p-4'>
      {/* Partners Carousel */}
      <div className="flex flex-col items-center slider-container">
        <div className="text-3xl md:text-4xl font-bold text-center mb-6">Наши Партнеры</div>
        <p className='text-md font-semibold text-center mb-6 md:w-3/5'>Репутация и доверие наших партнеров для нас на первом месте. Мы обеспечиваем заказчиков полным сопровождением и поддержкой на всех этапах совместной работы и готовы решить любые задачи индивидуально.</p>
        <Slider {...settings}>
          {partners.map((partner, index) => (
            <div key={index} className="px-2">
              <Partner logo={partner.logo} title={partner.title} />
            </div>
          ))}
        </Slider>
        {/* <div className="text-center pb-5 md:px-52">Репутация и доверие наших партнеров для нас на первом месте. Мы обеспечиваем заказчиков полным сопровождением и поддержкой на всех этапах совместной работы и готовы решить любые задачи индивидуально.</div> */}
      </div>
    </section>
  );
}

export default OurPartnersSection;