function LeaderMessageSection() {
    const leaderImage = "/leader.png";
    const leaderName = "Якушенков Артем Валерьевич";
    const leaderTitle = "Директор ООО«Артлок»";

    return (
        <section id="leaderMessage" className="flex flex-col md:flex-row my-10 items-center justify-between">
            <div className="w-full md:w-1/2 overflow-hidden  relative rounded mb-10">
                {/* Using Tailwind CSS for Object Fit Cover */}
                <img src={leaderImage} alt="Leader" className="min-w-full h-[70vh] object-cover"  />
            </div>

            <div className="w-full md:w-1/2 h-full flex items-center justify-center px-4 md:px-10" >
                <div className="space-y-2">
                    <h2 className="text-3xl md:text-4xl font-bold mb-6  md:text-left text-center">Обращение руководителя</h2>
                    <p>Мы занимается складами ответственного хранения уже более 12 лет. Наши склады работают по системе «ВСЕ ВКЛЮЧЕНО», обеспечивая клиентов круглосуточной технической поддержкой, бесперебойным функционированием складских площадей и оперативным решением поставленных задач. </p>
                    <p>Мы полностью берем на себя организацию хранения вашей продукции, а вы концентрируете внимание на развитие своего бизнеса.</p>
                    <br/>
                    <p className="text-xl font-serif font-bold">{leaderName}</p>
                    <p className="font-serif font-bold">{leaderTitle}</p>
                </div>
            </div>
        </section>
    );
}
export default LeaderMessageSection;