import React from 'react';

function PrivacyPolicyPage() {
    return (
        <div className="container mx-auto p-8 bg-white my-8 text-gray-800">
            <h1 className="text-3xl font-semibold mb-4">Политика конфиденциальности интернет-сайта</h1>
            <p>
                Настоящая Политика в области обработки персональных данных и конфиденциальности персональной информации (далее — Политика) действует в отношении всей информации, размещенной на сайте в сети Интернет по адресу: www.artlok.ru (далее — Сайт), которую посетители, другие пользователи Сайта могут получить о Пользователе во время использования Сайта, его сервисов, программ и продуктов.
            </p>

            <h3 className="text-xl font-semibold mt-4">1. Общие положения</h3>
            <p className="my-2">1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:</p>
            <ul className="pl-8 mt-1 space-y-2">
                <li>1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учетной записи) или в процессе использования сервисов Сайта, включая персональные данные Пользователя. Обязательная для предоставления сервисов информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение.</li>
                <li>1.1.2. Администрация Сайта в общем случае не проверяет достоверность персональной информации, предоставляемой пользователями, и не осуществляет контроль за их дееспособностью. Однако Администрация Сайта исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в форме регистрации, и поддерживает эту информацию в актуальном состоянии. Риск предоставления недостоверной информации несет предоставивший ее пользователь.</li>
                <li>1.1.3. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация.</li>
                <li>1.1.4. Иная информация о Пользователе, обработка которой предусмотрена Соглашением об использовании Сайта.</li>
                <li>1.1.5. Настоящая Политика конфиденциальности применяется только к Сайту <a href="http://www.artlok.ru" className="text-blue-500 hover:underline">www.artlok.ru</a>. Сайт <a href="http://www.artlok.ru" className="text-blue-500 hover:underline">www.artlok.ru</a> не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте <a href="http://www.artlok.ru" className="text-blue-500 hover:underline">www.artlok.ru</a>.</li>
            </ul>

            <h3 className="text-xl font-semibold mt-4">2. Цели обработки персональной информации пользователей</h3>
            <p className="my-2">2.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.</p>
            <p className="my-2">2.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</p>
            <ul className="pl-8 mt-1 space-y-2">
                <li>2.2.1. Идентификации Пользователя, зарегистрированного на Сайте, для использования всеми доступными сервисами Сайта, программами и продуктами.</li>
                <li>2.2.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</li>
                <li>2.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.</li>
                <li>2.2.4. Улучшение качества работы Сайта, удобства использования, разработка новых сервисов и услуг.</li>
                <li>2.2.5. Осуществление рекламной деятельности.</li>
                <li>2.2.6. Проведение статистических и иных исследований на основе обезличенных данных.</li>
            </ul>

            <h3 className="text-xl font-semibold mt-4">3. Условия обработки персональной информации пользователя и ее передачи третьим лицам</h3>
            <p className="my-2">3.1. Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных сервисов.</p>
            <p className="my-2">3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.</p>
            <p className="my-2">3.3. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
            <ul className="pl-8 mt-1 space-y-2">
                <li>3.3.1. Пользователь выразил согласие на такие действия.</li>
                <li>3.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения определенного соглашения или договора с Пользователем.</li>
                <li>3.3.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.</li>
                <li>3.3.4. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</li>
                <li>3.3.5. В целях обеспечения возможности защиты прав и законных интересов Администрации Сайта или третьих лиц в случаях, когда пользователь нарушает Условия пользования Сайта.</li>
            </ul>
            <p className="my-2">3.4. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств. Обработка персональных данных Пользователей осуществляется в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».</p>
            <p className="my-2">3.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
            <p className="my-2">3.6. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
            <p className="my-2">3.7. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>

            <h3 className="text-xl font-semibold mt-4">4. Обязательства сторон</h3>
            <p className="my-2">4.1. Пользователь обязан:</p>
            <ul className="pl-8 mt-1 space-y-2">
                <li>4.1.1. Предоставить информацию о персональных данных, необходимую для пользования сервисами Сайта.</li>
                <li>4.1.2. Обновлять, дополнять, удалять предоставленную информацию о персональных данных или ее часть в случае изменения данной информации.</li>
            </ul>
            <p className="my-2">4.2. Администрация Сайта обязана:</p>
            <ul className="pl-8 mt-1 space-y-2">
                <li>4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике конфиденциальности.</li>
                <li>4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой конфиденциальности.</li>
                <li>4.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.</li>
                <li>4.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или неправомерных действий.</li>
            </ul>

            <h3 className="text-xl font-semibold mt-4">5. Ответственность Сторон</h3>
            <p className="my-2">5.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации.</p>
            <p className="my-2">5.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет ответственности, если данная конфиденциальная информация:</p>
            <ul className="pl-8 mt-1 space-y-2">
                <li>5.2.1. Стала публичным достоянием до ее утраты или разглашения.</li>
                <li>5.2.2. Была получена от третьей стороны до момента ее получения Администрацией Сайта.</li>
                <li>5.2.3. Была разглашена с согласия Пользователя.</li>
            </ul>
            <p className="my-2">5.3. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта применяется действующее законодательство Российской Федерации.</p>

            <h3 className="text-xl font-semibold mt-4">6. Дополнительные условия</h3>
            <p className="my-2">6.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
            <p className="my-2">6.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать Администрации сайта по адресу <a href="http://www.artlok.ru" className="text-blue-500 hover:underline">www.artlok.ru</a>. Сайт <a href="http://www.artlok.ru" className="text-blue-500 hover:underline">www.artlok.ru</a>.</p>
            {/* Include other sections similarly */}


        </div>
    );
}

export default PrivacyPolicyPage;
