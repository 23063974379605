import React, { useState } from 'react';

function Counter({ end, duration, onCountEnd, startCounting }) {
  const [count, setCount] = useState(0);

  const countUp = () => {
    if (!startCounting) return;

    const endCount = parseInt(end, 10);
    const incrementTime = duration / 100; // Number of updates (e.g., 100 updates in total)
    const incrementValue = endCount / 100; // Value to increase each time

    let currentCount = 0;
    const timer = setInterval(() => {
      currentCount += incrementValue;
      if (currentCount >= endCount) {
        clearInterval(timer);
        setCount(endCount); // Ensure final count is exactly endCount
        if (onCountEnd) onCountEnd();
      } else {
        setCount(Math.round(currentCount));
      }
    }, incrementTime);
  };

  if (startCounting && count === 0) {
    countUp();
  }

  return <div className="text-7xl font-bold">{count.toLocaleString()}</div>;
}

export default Counter;
