import CircularStatistic from '../components/CircularStatistic';
function GrowthPolicySection() {
    const efficiencyMetrics = [
        { label: 'Энергопотребление', value: 3 },
        { label: 'Усовершенствованная система освещения', value: 90 },
        { label: 'Затраты на электроэнергию', value: 2 },
        { label: 'Энергоэффективность оборудования на уровне класса А', value: 60 },
    ];
    return (
        <section id="growthPolicy" className="my-10 px-4">


            <div className="flex flex-col md:flex-row items-center">

                <div className="flex flex-col items-center gap-5 w-full md:w-1/2">
                    <h2 className="text-4xl font-bold  md:text-left text-center">Политика устойчивого развития</h2>
                    <a href="/Декларация экологической эффективности.doc" download="Декларация экологической эффективности.doc" className='underline hover:text-accent2 font-semibold'>
                        Скачать Декларацию
                    </a>
                    {/* <img src="/bgsvgs/growth-policy.webp" alt="Growth Policy" className="w-3/4 px-4 md:px-16" /> */}
                </div>

                <div className="flex flex-col md:w-1/2" >
                    <p className="pb-4">
                        Мы стремимся снизить влияние на экологию, которую мы оказываем своей деятельностью: мы внедряем на наших объектах раздельный сбор мусора, отказались от избыточного использования пластика в офисах и на складах, и непрерывно снижаем объем отходов, возникающих в результате нашей деятельности. Для того, чтобы быть убежденными в том, что наши действия результативны и последовательны, мы запланировали внедрение на 2024 года систему экологического менеджмента по стандарту ISO 14001:2015.
                    </p>
                    <h3 className="text-3xl font-semibold pb-4">Показатели Экологической Эффективности:</h3>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-6 p-10 md:p-0 text-center">
                        {efficiencyMetrics.map((metric, index) => (
                            <CircularStatistic key={index} value={metric.value} text={metric.label} />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
export default GrowthPolicySection;