// import ContactForm from "../components/ContactForm";
// import warehousesData from "../data/warehousesData";

function ContactUsSection(){
    return(
        <section className="my-8 p-4">
          <div className="flex flex-col w-full gap-5 pb-6 md:pb-0">
          <h1 className="text-3xl md:text-4xl font-bold text-left">
            Трансформируйте свою деятельность с помощью наших экспертных логистических решений
          </h1>
            {/* Service 1 */}
            {/* <div className="">
              <h2 className="text-2xl font-semibold">Эффективные складские помещения</h2>
              <p>Воспользуйтесь нашей обширной сетью высокотехнологичных складов, стратегически расположенных для оптимальной эффективности распределения. Наши решения сокращают накладные расходы, одновременно повышая вашу эксплуатационную гибкость.</p>
              
            </div> */}

            {/* Service 2 */}
            <div className="">
              <h2 className="text-2xl font-semibold">Индивидуальное планирование логистики</h2>
              <p>Пользуйтесь нашей обширной сетью оптимальных складских решений с комплексом складских услуг без ненужных излишеств. Склады отвечают современным требованиям. Наши решения сокращают накладные расходы, одновременно повышая вашу эксплуатационную гибкость, обеспечивая надежность</p>

            </div>

            {/* Service 3 */}
            {/* <div className="">
              <h2 className="text-2xl font-semibold">Интеграция передовых технологий</h2>
              <p>Будьте впереди с нашими интеллектуальными логистическими решениями, основанными на передовых технологиях. Отслеживание в режиме реального времени позволяет вам быть спокойным и сосредоточиться на своем бизнесе.</p>
              
            </div> */}
          </div>

          {/* <div className="w-full md:w-1/2">
            <ContactForm warehouses={warehousesData}></ContactForm>
          </div> */}
     
      </section>
    );
}

export default ContactUsSection;