import { FaHandshake, FaClipboardCheck, FaDove, FaPeopleCarry, FaHiking, FaEye } from 'react-icons/fa';

const valuesData = [
    {
        id:  'client',
        icon:  <FaHandshake/>,
        title: "Клиентоцентричность",
        description: "Все процессы в нашей компании выстроены вокруг наших клиентов и их потребностей.",
        
    },
    {
        id:  'professional',
        icon:  <FaClipboardCheck/>,
        title: "Профессионализм",
        description: "Мы стремимся постоянно повышать свой уровень профессионализма и хотим быть лучшими в своем деле.",
    },
    {
        id:  'honesty',
        icon:  <FaDove/>,
        title: "Честность",
        description: "Мы верны принятым обязательствам и искренни перед партнерами и собой.",
        image: "/values/book.png",
    },
    {
        id:  'team',
        icon:  <FaPeopleCarry/>,
        title: "Командная работа и сотрудничество",
        description: "Мы достигаем целей вместе.",
    
    },
    {
        id:  'active',
        icon:  <FaHiking/>,
        title: "Активное созидание",
        description: "Мы верим, что можем преобразить мир, выйдя за рамки возможного.",
    },
    {
        id:  'attentive',
        icon:  <FaEye/>,
        title: "Вовлеченность",
        description: "Мы поддерживаем проактивный подход к работе",
        
    },
];

export default valuesData;