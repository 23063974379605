import { useState } from 'react';
import { motion } from 'framer-motion';
import benefitsData from '../data/benefitsData';

function PartnerBenefitsSection() {
  const [expanded, setExpanded] = useState(null); // For accordion functionality

  const toggleAccordion = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  return (
    <section id="partnerBenefits" className="flex flex-col md:flex-row justify-between items-center my-10 gap-0 md:gap-10 min-h-[650px] px-4">
      
     
        <h2 className="text-3xl md:text-4xl font-bold md:text-left text-center">Преимущества сотрудничества</h2>
          {/* SVG Graphic */}
          {/* <img src="/bgsvgs/5.svg" alt="Warehouse" className="w-full" /> */}
   
        
        <div className=" flex flex-col w-full md:w-1/2 md:px-8 py-4">
          {benefitsData.map((benefit) => (
            <motion.div
              key={benefit.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="flex items-center p-4 border-b border-gray-200 cursor-pointer"
              onClick={() => toggleAccordion(benefit.id)}
            >
              <div className="pr-4 text-accent2 text-2xl">
                {benefit.icon}
              </div>
              <div>
                <h3 className="text-xl font-semibold">{benefit.title}</h3>
                {expanded === benefit.id && <p>{benefit.description}</p>}
              </div>
            </motion.div>
          ))}
        </div>
     
    </section>
  );
}

export default PartnerBenefitsSection;