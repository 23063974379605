// Example services data
const servicesData = [
    {
      id: 1,
      title: "Поступление Товара",
      description: [
        "Перемещение товарно-материальных ценностей (ТМЦ) из транспортного средства в помещение временного хранения",
        "Проверка поставки по количеству и качеству, согласно документам от поставщика",
        "Оформление ТМЦ в информационную систему предприятия согласно поступившим документам"
      ],
      imageUrl: "/services/4.svg",
    },
    {
      id: 2,
      title: "Размещение на хранение",
      description: [
        "Хранение запаса",
        "Создание складской грузовой единицы",
        "Перемещение грузовой единицы к месту хранения",
        "Отправка товара штабельной укладкой или на стеллажное оборудование согласно адресной системе склада",
        "Создание условия для длительного или краткосрочного хранения товаров",
        "Контроль и управление товарных остатков на складе",
      ],
      imageUrl: "/services/3.svg",
    },
    {
      id: 3,
      title: "Отгрузка Товаров",
      description: [
        "Создание отборочного листа, согласно поступившей заявке",
        "Сборка товара по номенклатуре",
        "Упаковка товара, формирование грузовых мест и укладка на специальное оборудование на поддон (при необходимости)",
        "Оформление исходящей документации, в соответствие с реальной сборкой",
        "Перемещение товара в зону отгрузки",
        "Отгрузка грузовых мест в транспортное средство; оформление транспортных накладных; отправка товара до потребителя; укладка складской тары и поддонов на место; контроль заказа клиента; информационное сопровождение отгрузки",
      ],
      imageUrl: "/services/2.svg",
    },
    {
      id: 4,
      title: "Оформление Документов",
      description: [
        "МХ-1 – 2 экземпляра",
        "МХ-3 – 2 экземпляра",
        "МХ-7 – при наличии расхождений по качеству и количеству 2 - 3 экземпляра",
        "Оформление документов от лица поклажедателя (по запросу)",
        "Оформление документов в ЭДО",
      ],
      imageUrl: "/services/doc.svg",
    },
  ];

  export default servicesData;