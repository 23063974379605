import { FaCity, FaHandshake, FaRegThumbsUp, FaRoad, FaWarehouse, FaNetworkWired, FaLaptopCode, FaShieldAlt } from 'react-icons/fa';
// Import other icons as needed

const benefitsData = [
    {
      id: 'cities',
      icon: <FaCity />,
      title: '12 городов присутствия',
      description: 'Астрахань, Екатеринбург, Казань, Краснодар, Москва, Нижний Новгород, Новосибирск, Пенза, Ростов на Дону, Самара (Кинель), Уфа', // Add detailed descriptions
    },
    {
      id: 'needs',
      icon: <FaHandshake />,
      title: 'Индивидуальный подход, в том числе открытие склада под потребности клиента',
      description: 'Предлагаем решения, наиболее соответствующие задачам клиентов.', // Add detailed descriptions
    },
    {
      id: 'standards',
      icon: <FaRegThumbsUp />,
      title: 'Высокие стандарты организации работ',
      description: 'Технологизация логистических процессов отражена в собственных стандартах компании.', // Add detailed descriptions
    },
    {
      id: 'railroad',
      icon: <FaRoad />,
      title: 'Все склады оснащены ЖД путями',
      description: 'Работаем в системе РЖД Этран, отслеживаем груз поклажедателей.', // Add detailed descriptions
    },
    {
      id: 'warehouses',
      icon: <FaWarehouse />,
      title: 'Технологии хранения адаптированы под потребности клиентов',
      description: 'Предлагаются наиболее оптимальные способы хранения товара.', // Add detailed descriptions
    },
    {
      id: 'networks',
      icon: <FaNetworkWired />,
      title: 'Интеграция информационных систем компаний и клиентов',
      description: 'Для эффективной и оперативной работы готовы автоматизировать связзь между информационными системами для получения единого информационного пространства и организации поддержки сквозных бизнес-процессов.', // Add detailed descriptions
    },
    {
      id: 'programming',
      icon: <FaLaptopCode />,
      title: 'Использование программных решений WMS',
      description: 'Система автоматизации управления складскими процессами и инфраструктурой склада в целом устанавливается по стандартам компании.', // Add detailed descriptions
    },
    {
      id: 'insurance',
      icon: <FaShieldAlt />,
      title: 'Страхование ответственности и товаров',
      description: 'Ежегодно заключаются договоры страхования товарных запасов.', // Add detailed descriptions
    },
    // ... other benefits
  ];

  export default benefitsData;