import { useState } from 'react';
import valuesData from '../data/valuesData';
import { motion } from 'framer-motion';

function OurValuesSection() {
    const [expanded, setExpanded] = useState(null); // For accordion functionality
    const toggleAccordion = (id) => {
        setExpanded(expanded === id ? null : id);
      };
    return (
        <section id="ourValues" className="flex flex-col md:flex-row items-center justify-between my-10 min-h-[450px] px-4">


                    <h2 className="text-3xl md:text-4xl font-bold  md:text-left text-center">Наши ценности</h2>
                    {/* SVG Graphic */}
                    {/* <img src="/bgsvgs/5.svg" alt="Warehouse" className="w-full" /> */}
      
                <div className="flex flex-col w-full md:w-1/2  md:px-8 py-4">
                    {valuesData.map((value) => (
                        <motion.div
                            key={value.id}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="flex items-center p-4 border-b border-gray-200 cursor-pointer"
                            onClick={() => toggleAccordion(value.id)}
                        >
                            <div className="pr-4 text-accent2 text-2xl">
                                {value.icon}
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold">{value.title}</h3>
                                {expanded === value.id && <p>{value.description}</p>}
                            </div>
                        </motion.div>
                    ))}
                </div>
            

            {/* <h2 className="text-3xl md:text-4xl font-bold text-center mb-6">Наши ценности</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full md:w-3/4 max-w-6xl ">
                {valuesData.map((value, index) => (
                    <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                        <img src={value.image} alt={value.title} className="w-full h-32 object-cover" />
                        <div className="p-6">
                            <h3 className="text-2xl font-semibold mb-2 text-gray-800">{value.title}</h3>
                            <p className="text-gray-600">{value.description}</p>
                        </div>
                    </div>
                ))}
            </div> */}
        </section>
    );
}
export default OurValuesSection;