import React, { useState, useEffect } from 'react';
// import { useGesture } from 'react-use-gesture';
import './Timeline.css'; // Import your CSS styles

const TimelineEvent = ({ date, isVisible }) => (
  <div className="timeline-event">
    <div className={`date ${isVisible ? 'in-focus' : ''} `}>{date}</div>
    <div className={`node ${isVisible ? 'in-focus' : ''}`}></div>
  </div>
);


const Timeline = ({ events }) => {
  const [activeEventIndex, setActiveEventIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveEventIndex((current) => (current + 1) % events.length);
    }, 4000); // Change every 3 seconds
    return () => clearInterval(interval);
  }, [events.length]);

  // const bind = useGesture({
  //   onDrag: ({ movement: [mx, my] }) => {
  //     if (my < 0) {
  //       setActiveEventIndex((current) => (current + 1) % events.length);
  //     } else if (my > 0) {
  //       setActiveEventIndex((current) => (current - 1 + events.length) % events.length);
  //     }
  //   },
  // });

  return (
    <div className="timeline-container">

      {events.map((event, index) => (
        <TimelineEvent key={index} {...event} isVisible={index === activeEventIndex} />
      ))}

      <div className="timeline-line"></div>


      {/* Render the static position details container with active event's details */}
      <div className="details">
        {events[activeEventIndex].image ? (
          <>
            <img
              src={events[activeEventIndex].image}
              alt={events[activeEventIndex].title}
              className="event-image"
            />
            <div className="text-overlay">
              <h3 className="text-2xl font-semibold mb-2">{events[activeEventIndex].title}</h3>
              <h4 className="text-xl font-semibold mb-2">{events[activeEventIndex].subtitle}</h4>
              <p>{events[activeEventIndex].text}</p>
            </div>
          </>
        ) : (
          <>
            <div className='text-only'>
              <h3 className="text-2xl font-semibold mb-2">{events[activeEventIndex].title}</h3>
              <h4 className="text-xl font-semibold mb-2">{events[activeEventIndex].subtitle}</h4>
              <p>{events[activeEventIndex].text}</p>
            </div>
          </>
        )}
      </div>

    </div>
  );
};
export default Timeline;
