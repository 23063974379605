import React from "react";
import LandingSection from "../sections/LandingSection";
// import OurServicesSection from "../sections/OurServicesSection";
import OurPartnersSection from "../sections/OurPartnersSection";
import ContactUsSection from "../sections/ContactUsSection";
function LandingPage() {

  return (

    <div className="container mx-auto">
      <LandingSection></LandingSection>
      {/* <OurServicesSection></OurServicesSection> */}
      <OurPartnersSection></OurPartnersSection>
      <ContactUsSection></ContactUsSection>
    </div>
  );
}

export default LandingPage;
