import React from 'react';

function HexTile({ text }) {
  return (
    <div className="hex-tile ">
      <div className="hex-inner hover:none md:hover:shadow-2xl md:transition-shadow md:duration-300 md:ease-in-out md:transform md:hover:scale-105">
        {text}
      </div>
    </div>
  );
}

export default HexTile;