function AnticorruptionPolicy() {
    return (
        <section id="anticorruptionPolicy" className="flex flex-col md:flex-row justify-between items-center my-10 px-4">
            
                <h2 className="text-4xl font-bold  md:text-left text-center order-1 md:order-2 mb-6 md:mb-0">Антикоррупционная политика</h2>
                <div className="flex flex-col items-left w-full md:w-1/2  order-2 md:order-1 ">
                    
                    
                        <p className="mb-4">
                            В ООО «Артлок» внедрена Антикоррупционная политика, которая отражает приверженность организации высоким этическим стандартам и принципам открытого и честного взаимодействия, а также стремление к совершенствованию корпоративной культуры, следованию лучшим практикам корпоративного управления и поддержанию деловой репутации на должном уровне.
                        </p>
                        <h3 className="text-3xl font-semibold mb-3">Основные цели:</h3>
                        <ul className="list-disc list-inside mb-4">
                            <li>Минимизировать риск вовлечения должностных лиц в коррупционные правонарушения;</li>
                            <li>Формирование непринятия коррупции в любых формах и проявлениях;</li>
                            <li>Знать и соблюдать ключевые нормы антикоррупционного законодательства.</li>
                        </ul>
                        <h3 className="text-3xl font-semibold mb-3">Принципы политики:</h3>
                        <ul className="list-disc list-inside mb-4">
                            <li>Неприятие коррупции в любых формах и проявлениях;</li>
                            <li>Соответствие Антикоррупционной политики действующему законодательству;</li>
                            <li>Личного примера руководства;</li>
                            <li>Информированности работников;</li>
                            <li>Эффективности антикоррупционных процедур;</li>
                            <li>Ответственности и неотвратимости наказания;</li>
                            <li>Постоянного контроля и регулярного мониторинга.</li>
                        </ul>
                 
                </div>
           
        </section>
    );
}
export default AnticorruptionPolicy;