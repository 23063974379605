import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faWhatsapp, faViber } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="bg-white text-gray-700 p-8  shadow">
            <div className="w-full flex flex-col md:flex-row justify-between">
                <div className='flex flex-col md:flex-row justify-start items-center gap-10'>
                    <div className="flex overflow-hidden rounded-lg">
                        <YMaps>
                            <Map defaultState={{ center: [55.766430, 49.121479], zoom: 13 }}>
                                <Placemark geometry={[55.766430, 49.121479]} properties={{ iconContent: 'Артлок' }} options={{ preset: 'islands#violetStretchyIcon' }} />
                            </Map>
                        </YMaps>
                    </div>
                    <div className='flex flex-col h-full justify-evenly'>
                        <h2 className=' font-bold'>Адрес:</h2>
                        <p className='text-sm font-bold'>Улица Братьев Петряевых, 5к2, офис 307, Приволжский район, Казань, 420021</p>
                        <h2 className=' font-bold mt-2'>Контакты:</h2>
                        <p className='text-sm font-bold'>+7(919)637-52-67 | +7(843)297-17-87 | +7(843)225-03-40</p>
                        <a href="mailto:artlok@bk.ru" className="text-accent1 hover:underline block text-sm font-bold">artlok@bk.ru</a>
                        <a href="mailto:a.yakushenkov@artlok.ru" className="text-accent1 hover:underline block text-sm font-bold mb-6 md:mb-0">a.yakushenkov@artlok.ru</a>
                        <div className="w-full md:w-auto  flex justify-center md:justify-start md:pb-0 pb-4">

                            <a href="https://t-do.ru/Artem24" aria-label="Telegram" className="text-blue-500 hover:text-blue-600 mr-4">
                                <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
                            </a>
                            <a href="https://wa.me/79196375267" aria-label="WhatsApp" className="text-green-500 hover:text-green-600 mr-4">
                                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                            </a>
                            <a href="viber://chat?number=+79196375267" aria-label="Viber" className="text-purple-500 hover:text-purple-600">
                                <FontAwesomeIcon icon={faViber} size="2x" />
                            </a>
                        </div>
                    </div>
                </div>
                {/* Logo and Company Info */}
                <div className="flex flex-col text-center items-center justify-start md:justify-start md:items-end md:text-right">
                    <img src='/Logo.svg' alt="Artlok Logo" className="w-48 mb-4"></img>
                    <p className='text-sm font-bold mt-2'>Copyright © 2020 Артлок. Все права защищены</p>
                    <Link to="/privacy-policy" className="hover:text-accent1 mt-2 inline-block text-sm font-bold underline">Политика Конфиденциальности</Link>
                    <Link to="/presentation" className="hover:text-accent1 mt-2 inline-block text-sm font-bold underline">Презентация</Link>
                    {/* <Link to="/about-us#documents" className="hover:text-accent1 mt-2 inline-block text-sm font-bold underline">Документы</Link> */}
                </div>

            </div>
        </footer>
    );
}

export default Footer;
