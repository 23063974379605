import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'; // Importing icons from heroicons
import Navigation from './Navigation'

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [playAnimation, setPlayAnimation] = useState(true);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        // Animation will play once and then disable
        const timer = setTimeout(() => {
            setPlayAnimation(false);
        }, 2000); // Duration of the animation

        return () => clearTimeout(timer);
    }, []);
    return (
        <header className="bg-white text-gray-700 sticky top-0 z-50">
            <div className='container flex flex-col md:flex-row items-center w-full'>
                <div className="flex w-full md:w-auto justify-between md:justify-normal items-center p-4">
                    <Link to="/"><img src="/Logo.svg" alt="Логотип Артлок" className={`block h-24 w-auto ${playAnimation ? 'logo-animation' : ''}`} /></Link>
                    <div className="md:hidden">
                        {isMenuOpen ? (
                            <XMarkIcon className="h-6 w-6" onClick={() => setIsMenuOpen(false)} />
                        ) : (
                            <Bars3Icon className="h-6 w-6" onClick={() => setIsMenuOpen(true)} />
                        )}
                    </div>
                </div>
                {/* Mobile Menu */}
                <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden w-full border-t menu-animation`}>
                    <Navigation onLinkClick={toggleMenu} />
                </div>
                {/* Desktop Menu */}
                <div className="hidden md:block w-full md:w-auto border-t md:border-none">
                    <Navigation onLinkClick={toggleMenu} />
                </div>
            </div>
        </header>
    );

}

export default Header;