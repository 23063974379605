import React, { useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faWhatsapp, faViber } from '@fortawesome/free-brands-svg-icons';
import warehousesData from '../data/warehousesData';

const ContactUsPage = () => {
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const handleChange = (event) => {
        const warehouseId = event.target.value;
        const selected = warehousesData.find(wh => wh.id === Number(warehouseId));
        setSelectedWarehouse(selected);
    };
    return (
        <div className="flex flex-col md:flex-row container mx-auto min-h-[800px] md:justify-evenly items-start mt-6 md:mt-20">
            <div className="md:flex flex-col w-auto hidden">
                <div className="overflow-hidden rounded-lg md:w-[600px] md:h-[600px] w-full h-[350px] mb-4">
                    <YMaps>
                        <Map defaultState={{ center: [55.766430, 49.121479], zoom: 13 }} className="w-full h-full">
                            <Placemark geometry={[55.766430, 49.121479]} properties={{ iconContent: 'Артлок' }} options={{ preset: 'islands#violetStretchyIcon' }} />
                        </Map>
                    </YMaps>
                </div>


            </div>
            <div className='md:w-1/2 px-4 order-1 md:order-2'>

                <h1 className="text-3xl md:text-4xl font-bold text-left mb-4">Свяжитесь с нами</h1>
                <h2 className='mb-2 font-bold'>Адрес:</h2>
                <p className='mb-2'>Улица Братьев Петряевых, 5к2, офис 307, Приволжский район, Казань, 420021</p>
                <h2 className='mb-2 font-bold'>Контакты:</h2>
                <p className='mb-2'>+7(919)637-52-67 | +7(843)297-17-87 | +7(843)225-03-40</p>
                <a href="mailto:artlok@bk.ru" className="text-accent1 hover:underline block mb-2">artlok@bk.ru</a>
                <a href="mailto:a.yakushenkov@artlok.ru" className="text-accent1 hover:underline block mb-2">a.yakushenkov@artlok.ru</a>
                <div className="w-full md:w-auto  flex justify-center md:justify-start md:pb-0 pb-4">

                    <a href="https://t-do.ru/Artem24" aria-label="Telegram" className="text-blue-500 hover:text-blue-600 mr-4">
                        <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
                    </a>
                    <a href="https://wa.me/79196375267" aria-label="WhatsApp" className="text-green-500 hover:text-green-600 mr-4">
                        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                    </a>
                    <a href="viber://chat?number=+79196375267" aria-label="Viber" className="text-purple-500 hover:text-purple-600">
                        <FontAwesomeIcon icon={faViber} size="2x" />
                    </a>
                </div>
                <div className='space-y-6 mt-4'>
                    <h1 className='text-3xl md:text-4xl font-bold text-left'>Наши Склады:</h1>
                    <select onChange={handleChange} className="shadow border rounded w-2/3 py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"  >
                        <option value="">Выберите Склад:</option>
                        {warehousesData.map(warehouse => (
                            <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                        ))}
                    </select>

                    {selectedWarehouse && (
                        <div className='space-y-2'>
                            <p><b>Адрес:</b> {selectedWarehouse.address}</p>
                            <p><b>Телефон:</b> {selectedWarehouse.phone}</p>
                            <p className='font-bold flex flex-row'>Эл. Почта:&nbsp;<a href={"mailto:" + selectedWarehouse.email} className="text-accent1 hover:underline block font-normal"> {selectedWarehouse.email}</a></p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactUsPage;
