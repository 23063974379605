const warehousesData = [
    {
        id: 1,
        name: 'Казань - Магистральная',
        lat: '55.732849',
        lon: '49.096766',
        manager: 'Менеджер 1',
        address: 'Магистральная, 77в, ​Приволжский район, Казань, ​420005',
        phone: '+7 (987) 297 17 87',
        email: 'artlok@bk.ru',
        image: '/warehouses/kazan/1.jpg',
        description: [
           "Площадь склада: 3000m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "возможность стеллажного хранения",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
            "единичный набор товара",
            "сбор комплектов",
        ],
        presentation: '/warehouses/kazan/Казань77.pdf',
        text: "Склад в Казани оснащены асфальтированным круглогодичным подъездом для автотранспорта, площадкой для маневрирования большегрузных автомобилей и профессиональной спецтехникой.",
    },
    {
        id: 2,
        name: 'Казань - Тихорецкая',
        lat: '55.729610',
        lon: '49.116098',
        manager: 'Менеджер 1',
        address: 'Тихорецкая улица, 19, Казань, 420054',
        phone: '+7 (917) 901 13 96',
        email: 'artlokkzn@bk.ru',
        image: '/warehouses/kazan/2.jpg',
        description: [
           "Площадь склада: 3000m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "возможность стеллажного хранения",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
            "единичный набор товара",
            "сбор комплектов",
        ],
        presentation: '/warehouses/kazan/Казань19.pdf',
        text: "Склад в Казани оснащены асфальтированным круглогодичным подъездом для автотранспорта, площадкой для маневрирования большегрузных автомобилей и профессиональной спецтехникой.",
    },
    {
        id: 3,
        name: 'Москва',
        lat: '55.662816',
        lon: '37.919990',
        manager: 'Менеджер 2',
        address: '1-й Панковский проезд, 5, Люберцы, Московская область, 140004',
        phone: '+7 (843) 225 03 40',
        email: 'oper@artlok.ru',
        image: '/warehouses/moscow/1.jpg',
        description: [
            "Площадь складов: 2000m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
         ],
         presentation: '/warehouses/moscow/Москва.pdf',
         text: "Склад в Москве оснащен асфальтированным круглогодичным подъездом для автотранспорта. Площадка для маневрирования большегрузных автомобилей, профессиональная спецтехника.",
    },
    {
        id: 4,
        name: 'Нижний Новгород',
        lat: '56.210687',
        lon: '43.807279',
        manager: 'Менеджер 3',
        address: 'Ореховская улица, 80к3, Нижний Новгород',
        phone: '+7 (917) 902 46 12',
        email: 'artloknn@bk.ru ',
        image: '/warehouses/nizhny/1.jpg',
        description: [
            "Площадь складов: 3000m²",
            "есть ж/д пути и ж/д рампа",
            "автомобильная рампа",
            "адресное хранение",
            "применение методов FIFO",
            "услуга последней мили",
            "кросс-докинг",
            "единичный набор товара",
            "сбор комплектов",
         ],
         presentation: '/warehouses/nizhny/НижнийНовгород.pdf',
         text: "Удобная транспортная развязка, прямой подъезд к складу и наличие сложной специальной техники обеспечат Вам комфорт и регулярный доступ на склад в Нижнем в Новгороде.",
    },
    {
        id: 5,
        name: 'Ростов-на-Дону',
        lat: '47.237908',
        lon: '39.740337',
        manager: 'Менеджер 4',
        address: 'Переулок Энергетиков, 3-5а/378/90, ​Пролетарский район, Ростов-на-Дону ​344000',
        phone: '+7 (950) 869 55 25',
        email: 'artlokrd@mail.ru',
        image: '/warehouses/rostov/1.jpg',
        description: [
            "Площадь складов: 4500m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
         ],
         presentation: '/warehouses/rostov/Ростов.pdf',
         text: "Огромная территория с удобными подъездными путями, полное техническое оснащение, возможность комфортной и быстрой доставки грузов по ЖД-путям позволят Вам быстро организовать переезд на склад в Ростове-на-Дону.",
    },
    {
        id: 6,
        name: 'Астрахань',
        lat: '46.325022',
        lon: '48.051774',
        manager: 'Менеджер 5',
        address: 'улица Станция Кутумная, 28А, Астрахань, 414057',
        phone: '+7 (843) 225 03 40',
        email: 'oper@artlok.ru',
        image: '/warehouses/astrahan/1.jpg',
        description: [
            "Площадь склада 2200m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "адресное хранение",
            "кросс-докинг", 
         ],
         presentation: '/warehouses/astrahan/Астрахань.pdf',
         text: "Наш склад в Астрахани занимает огромную охраняемую территорию, организован с точки зрения техники и персонала, обладает удобным подъездом и площадками для крупнотоннажных транспортных средств.",
    },
    {
        id: 7,
        name: 'Краснодар',
        lat: '45.048698',
        lon: '39.084997',
        manager: 'Менеджер 6',
        address: 'Улица Новороссийская, 61а, ​Восточная промышленная зона м-н, Карасунский округ, Краснодар, ​350080',
        phone: '+7 (917) 902 46 11',
        email: 'krasnodar@artlok.ru',
        image: '/warehouses/krasnodar/1.jpg',
        description: [
            "Площадь складов: 3500m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
         ],
         presentation: '/warehouses/krasnodar/Краснодар.pdf',
         text: "Комфортная транспортная развязка, безопасность и оснащенность специализированной грузовой техникой максимально ускорят процессы организации доставки Ваших товаров на склад ответственного хранения в Краснодаре.",
    },
    {
        id: 8,
        name: 'Уфа',
        lat: '54.613697',
        lon: '55.933233',
        manager: 'Менеджер 7',
        address: 'Школьная улица, 6А, село Чесноковка, Уфимский район, Республика Башкортостан, 450591',
        phone: '+7 (843) 225 03 40',
        email: 'oper@artlok.ru',
        image: '/warehouses/ufa/1.jpg',
        description: [
            "Площадь складов: 800m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
         ],
         presentation: '/warehouses/ufa/Уфа.pdf',
         text: "Склад в Уфе принимает крупногабаритные грузы и обладает асфальтированными подъездами и площадками для погрузо-разгрузочных работ.",
    },
    {
        id: 9,
        name: 'Кинель',
        lat: '53.231031',
        lon: '50.595120',
        manager: 'Менеджер 8',
        address: 'Промышленная улица, 13, Кинель, Самарская область',
        phone: '+7 (919) 800 48 52',
        email: 'samara@artlok.ru',
        image: '/warehouses/kinel/1.jpg',
        description: [
            "Площадь складов: 20000m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "возможность стеллажного хранения",
            "автоматизированная система управления складом WMS",
            "маркировка и штрих-кодирование товара",
            "адресное хранение",
            "применение методов FIFO",
            "услуга последней мили",
            "кросс-докинг",
            "возможность переупаковки товара",
            "единичный набор товара",
            "сбор комплектов",
         ],
         presentation: '/warehouses/kinel/СамараКинель.pdf',
         text: "",
    },
    {
        id: 10,
        name: 'Пенза',
        lat: '53.239218',
        lon: '45.020723',
        manager: 'Менеджер 8',
        address: 'Совхозная улица, 15Г, Пенза, 440015',
        phone: '+7 (987) 230 25 92',
        email: 'penza@artlok.ru',
        image: '/warehouses/penza/1.jpg',
        description: [
            "Площадь складов: 500m²",
            "есть автомобильная рампа",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
         ],
         presentation: '/warehouses/penza/Пенза.pdf',
         text: "",
    },
    {
        id: 11,
        name: 'Екатеринбург',
        lat: '56.867611',
        lon: '60.516006',
        manager: 'Менеджер 8',
        address: 'улица Электродепо, 3, Екатеринбург, Свердловская область, 620050',
        phone: '+7 (917) 232 56 54',
        email: 'ekb@artlok.ru',
        image: '/warehouses/ekaterinburg/1.jpg',
        description: [
            "Площадь складов: 800m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "возможность стеллажного хранения",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
            "единичный набор товара",
            "сбор комплектов",
         ],
         presentation: '/warehouses/ekaterinburg/Екатеринбург.pdf',
         text: "",
    },
    {
        id: 12,
        name: 'Новосибирск',
        lat: '54.966406',
        lon: '82.828783',
        manager: 'Менеджер 8',
        address: 'Толмачёвское шоссе, 1к2, Новосибирск, 630052',
        phone: '+7 (843) 225 03 40',
        email: 'oper@artlok.ru',
        image: '/warehouses/novosibirsk/1.jpg',
        description: [
            "Площадь складов: 800m²",
            "есть ж/д пути и ж/д рампа",
            "есть автомобильная рампа",
            "возможность стеллажного хранения",
            "адресное хранение",
            "услуга последней мили",
            "кросс-докинг",
         ],
         presentation: '/warehouses/novosibirsk/Новосибирск.pdf',
         text: "",
    },
    {
        id: 13,
        name: 'Волгоград',
        lat: '48.778487',
        lon: '44.461998',
        manager: 'Менеджер 8',
        address: 'улица Дорожников, 11, рабочий посёлок Городище, Волгоградская область',
        phone: '+7 (843) 225 03 40',
        email: 'oper@artlok.ru',
        image: '/warehouses/volgograd/2.jpeg',
        description: [
            "Площадь складов: 2000m²",
            "есть автомобильная рампа",
            "возможность стеллажного хранения",
            "услуга последней мили",
            "кросс-докинг",
            "единичный набор товара",
            "сбор комплектов",
         ],
         presentation: '/warehouses/volgograd/Волгоград.pdf',
         text: "",
    },
];

export default warehousesData;