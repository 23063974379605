import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import AboutUsPage from './pages/AboutUsPage';
import PresentationPage from "./pages/PresentationPage";
import { YMaps } from '@pbe/react-yandex-maps';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ScrollToTop from "./components/ScrollToTop";
import WarehousePage from "./pages/WarehousePage";
import ServicesPage from "./pages/ServicesPage";
import ContactUsPage from "./pages/ContactUsPage";


function App() {
  return (
    <YMaps query={{ lang: 'en_RU' }}>
    <div className="App min-h-screen bg-gradient-to-t from-accent1/40 to-accent2/40  text-white">
    <Router>
      <Header/>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about-us" element={<AboutUsPage/>} />
        <Route path="/presentation" element={<PresentationPage/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
        <Route path="/warehouses" element={<WarehousePage/>} />
        <Route path="/services" element={<ServicesPage/>} />
        <Route path="/contact" element={<ContactUsPage/>} />
        {/* Define routes here */}
      </Routes>
      <Footer/>
    </Router>
    </div>
    </YMaps>
  );
}

export default App;

