import React from 'react';

function Partner({ logo, title }) {
  return (
    <div className="flex flex-col items-center justify-center ">
      <div className='rounded-full overflow-hidden bg-white'>
      <img src={logo} alt={`${title} Logo`} className="h-24 w-24 md:h-32 md:w-32 object-contain" />
      </div>
      
      <p className="text-sm font-bold text-white mt-2">{title}</p>
    </div>
  );
}

export default Partner;