const companyHistoryData = [
    {
        date: "2011",
        title: "г. Казань",
        subtitle: "Запуск Склада",
        image: "/cities/Казань.jpg"
    },
    {
        date: "2012",
        title: "г. Нижний Новгород",
        subtitle: "Запуск Склада",
        image: "/cities/Нижний.jpg"
    },
    {
        date: "2012",
        title: "г. Ростов На Дону",
        subtitle: "Запуск Склада",
        image: "/cities/Ростов.jpg"
    },
    {
        date: "2018",
        title: "г. Пенза",
        subtitle: "Запуск Склада",
        image: "/cities/Пенза.jpg"
    },
    {
        date: "2018",
        title: "г. Краснодар",
        subtitle: "Запуск Склада",
        image: "/cities/Краснодар.jpg"
    },
    {
        date: "2018",
        title: "г. Москва",
        subtitle: "Запуск Склада",
        image: "/cities/Москва.jpg"
    },
    {
        date: "2019",
        title: "г. Уфа",
        subtitle: "Запуск Склада",
        image: "/cities/Уфа.jpg"
    },
    {
        date: "2020",
        title: "г. Астрахань",
        subtitle: "Запуск Склада",
        image: "/cities/Астрахань 2.jpg"
    },
    {
        date: "2020",
        title: "г. Екатеринбург",
        subtitle: "Запуск Склада",
        image: "/cities/Екат.jpg"
    },
    {
        date: "2021",
        title: "г. Кинель (Самарская Область)",
        subtitle: "Запуск Склада",
        image: "/cities/Кинель.jpeg"
    },
    {
        date: "2022",
        title: "г. Новосибирск",
        subtitle: "Запуск Склада",
        image: "/cities/Новосиб 2.jpg"
    },
    {
        date: "2024",
        title: "г. Волгоград",
        subtitle: "Запуск Склада",
        image: "/cities/Волгоград.jpg"
    },
];

export default companyHistoryData;