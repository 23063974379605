import companyHistoryData from '../data/companyHistoryData';
import Timeline from '../components/Timeline.js';

function CompanyHistorySection() {
    return (
        <section id="companyHistory" className="my-8">
            <div className='flex flex-col md:flex-row items-center'>
                <div className="flex flex-row w-full md:w-1/2 px-4 md:p-0 h-[650px] mb-10 md:mb-0">
                    <Timeline events={companyHistoryData} />
                </div>
                <div className='flex flex-col w-full md:w-1/2  md:pl-20 px-4'>
                    <h2 className="text-3xl md:text-4xl font-bold mb-6 md:text-left text-center">История компании</h2>
                    <p>Мы начинали свою деятельность как поставщики бумаги и бумажной продукции на региональном рынке.
                        Прямо скажем, что конкурентов у нас было достаточно, но с большинством из них у нас были партнерские отношения, зачастую, мы предоставляли нашим «конкурентам – партнерам» свои площади для хранения товара, и именно это наше направление деятельности, как говорят, в один прекрасный день, переросло в самостоятельный бизнес по предоставлению услуг хранения.
                        И наши бывшие конкуренты стали нашими партнерами, поскольку за время совместной работы мы смогли заслужить доверие и проявить себя с профессиональной точки зрения.
                    </p>
                    <br></br>
                    <p>    
                        Сегодня Артлок – один из крупнейших хранителей бумаги в России. Мы развиваемся вместе с нашими клиентами и прагматично заинтересованы в их экономическом успехе.
                        Наши склады расположены в 12 городах России, а широкая номенклатура хранящегося товара включает в себя не только бумажно – целлюлозную продукцию, но и машиностроительные, сельскохозяйственные, сырьевые, пищевые, строительные товары, а также товары народного потребления.
                        Компания Артлок является ярким представителем МСП, и в этом наш плюс – мы умеем быстро реагировать на запросы наших клиентов и очень рады, когда совместно достигаем успеха.
                    </p>
                </div>
            </div>
        </section>

    );
}

export default CompanyHistorySection;