import React, {useState, useEffect} from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import { FiArrowLeft } from 'react-icons/fi';
import "./WarehouseDetails.css"; // Import your CSS styles

// The workerSrc property must be specified.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const WarehouseDetails = ({
  warehouse,
  isPresentationShown,
  togglePresentation,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  // Function to render bullet-point descriptions
  const renderDescriptions = () => {
    return warehouse.description.map((item, index) => (
      <li key={index} className="list-disc ml-4 font-semibold">
        {item}
      </li>
    ));
  };
  
  useEffect(() => {
    // Update the scale based on the window width or height
    const updateScale = () => {
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const pageWidth = 1707; // Adjusted for landscape A4 page at 72 dpi (A4 landscape width)
      const pageHeight = 960; // Adjusted for landscape A4 page at 72 dpi (A4 landscape height)
      const scaleByWidth = screenWidth / pageWidth;
      const scaleByHeight = screenHeight / pageHeight;
      // Choose the smaller scale to ensure the PDF fits within the screen both width and height wise
      const newScale = Math.min(scaleByWidth, scaleByHeight) * 1.4;
      setScale(newScale);
    };

    // Set scale on initial load
    updateScale();

    // Add event listener to resize the scale when window resizes
    window.addEventListener("resize", updateScale);

    // Cleanup listener when component unmounts
    return () => window.removeEventListener("resize", updateScale);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (!warehouse) return null; // or a placeholder content

  return (
    
    <div className={`modal bg-white md:shadow-2xl md:rounded-2xl rounded-none ${isPresentationShown ? 'p-2': 'relative flex flex-col  h-full'}`}>
     
      {isPresentationShown ? (
        <div>
          <button className="text-red-500 mb-1 text-lg" onClick={togglePresentation}>
            <FiArrowLeft size={24} /> {/* Arrow back icon */}
          </button>
          <Document
            file={warehouse.presentation}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                scale={scale}
              />
            ))}
          </Document>
        </div>
      ) : (
        <div className="relative flex flex-col h-full">
          <div className="absolute top-0 left-0 right-0 bottom-1/4 overflow-hidden ">
            {" "}
            {/* Adjust paddingBottom to control image height */}
            {warehouse.image && (
              <img
                src={warehouse.image}
                alt={warehouse.name}
                className="block w-full h-full object-cover"
              />
            )}
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/50 p-6 flex flex-col justify-end">
              <h3 className="text-xl font-bold text-white">{warehouse.name}</h3>
              <ul className="text-white">{renderDescriptions()}</ul>
              <p className="text-white">{warehouse.text}</p>
            </div>
          </div>

          <div className="relative h-full flex flex-col justify-end pt-0 px-4 pb-4">
            <button className="text-blue-500 mb-1" onClick={togglePresentation}>Показать презентацию</button>
            {/* <p className="text-sm text-gray-600">Менеджер: {warehouse.manager}</p> */}
            <p className="text-lg text-gray-600">Адрес: {warehouse.address}</p>
            <p className="text-sm text-gray-600">Телефон: {warehouse.phone}</p>
            <p className="text-sm text-gray-600">
              Эл. Почта:{" "}
              <a href={`mailto:${warehouse.email}`} className="text-blue-500">
                {warehouse.email}
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default WarehouseDetails;
